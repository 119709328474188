@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/placeholders';

.container {
  @extend %flex;

  padding: $spacing-lg;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-lg;

  background-color: $color-primary;

  width: 100%;
  height: 100vh;
}